import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Content = styled('div')`
  width: clamp(400px, 50vw, 960px);
  margin: 0 auto;
  margin-bottom: 240px;
  position:relative;
  top: 160px;
  counter-reset: section;
  & > *{
    text-align: left;
  }
  h3 {
    margin-top: 24px;
      counter-increment: section;
    &:before{
      content: counter(section) ". ";
    }
  }
  & span.link {
    display: block;
    margin-top: 48px;
  }
`

const PrivacyPage = () => (
  <Layout navForced>
    <SEO title={'Sheetplanner - Privacy'}/>
    <Content>

      <p><em>Effective: October 26th 2018.</em></p>
      <h3>What personal information does SheetPlanner Software, LLC collect and store?</h3>
      <p>Personal information you provide us.<br/>
        We receive and store any information you provide us via our website or in any other way.<br/>
        The types of information we collect include your full name, company name, email address, and phone number in
        addition to any information provided to resolve support issues.<br/>
        You can choose not to provide us with certain information but this may result in us not being able to provide
        technical support as requested.</p>
      <h3>How do we use the information we collect?</h3>
      <p>The personal information you provide is used for providing technical support, answering questions regarding
        product features and functionality, and in communicating product updates, bug fixes, new features, releases and
        promotions.</p>
      <h3>Will we share any of the personal information we collect?</h3>
      <p>We will not rent or sell your Personal Information to anyone. We share your information only as stated
        below:</p>
      <h4>Agents:</h4>
      <p>We may employ other companies and individuals to perform tasks and provide services such as technical support
        to you. Agents do not have the right to use your Personal Information beyond what is necessary to assist us in
        performing tasks and providing services to you. You hereby consent to our sharing Personal Information for the
        above purposes.</p>
      <h3>Is my personal information secure?</h3>
      <p>We endeavor to protect your Personal Information and to ensure it is kept private, however we cannot guarantee
        the security of your Personal Information. Unauthorized entry or use of software or hardware and other factors
        may compromise security at any time.</p>
      <p>We may provide links to other companies and services via our website and we are not responsible for Personal
        Information provided to other entities via a link from our website or via any other form of communication.</p>
      <h3>Data retention policy</h3>
      <p>We retain the Personal Information you provide us indefinitely or until such a time as the business ceases
        operations.</p>
      <h3>Your rights.</h3>
      <p>You may opt not to disclose information although as previously stated that may effect your ability to attain
        technical support.<br/>
          You may request that your Personal Information be deleted.<br/>
          You may opt out of email that you receive from us detailing new releases, new features and promotions via the
          email unsubscribe option.</p>
      <h3>Changes to this Privacy Policy</h3>
      <p>We may amend this Privacy Policy from time to time. Users are bound by any changes to the privacy policy when
        he or she uses our products and services after such changes are first posted</p>
      <h3>Questions or concerns?</h3>
      <p>If you have any questions or concerns regarding privacy as it relates to our products or services, please
        contact support@sheetplanner.com and we will make every effort to address your concerns.</p>


      <Link to="/"><span className={"link"}>Go back to the homepage</span></Link>
    </Content>
  </Layout>
)

export default PrivacyPage
